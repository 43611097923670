    const OroData = [
        {src: 'https://player.vimeo.com/progressive_redirect/playback/792133238/rendition/720p/file.mp4?loc=external&signature=b0f1edd3e9c03395f34579ad2a831e7691c396cf8c23e69c9303df4e0e1e7439', type: 'mp4'},
        {src: 'oro/oro-2.jpg', type: 'image'},
        {src: 'oro/oro-3.jpg', type: 'image'},
        {src: 'https://player.vimeo.com/progressive_redirect/playback/792133274/rendition/720p/file.mp4?loc=external&signature=ce9db3e8830c0d407febf92eb138d9fc725d1e757297914e03d8f219a4e7d011', type: 'mp4'},
        {src: 'https://player.vimeo.com/progressive_redirect/playback/792133304/rendition/720p/file.mp4?loc=external&signature=6a71662a110ce3e5298eb6fe1191360837ddec4d2527f72c9fc4bb5d7ed73d68', type: 'mp4'},
        {src: 'https://player.vimeo.com/progressive_redirect/playback/792133326/rendition/720p/file.mp4?loc=external&signature=dea7ce47e7b86cd27b7c20583b984ff642c7fe4ba945384c8c2b6d840faf78a5', type: 'mp4'},
        {src: 'https://player.vimeo.com/progressive_redirect/playback/792133368/rendition/720p/file.mp4?loc=external&signature=d99663a42d1dd00baf303518f45a769a99101d8159416908f221e5c31a51d510', type: 'mp4'},
        {src: 'oro/oro-7.jpg', type: 'image'},
        {src: 'oro/oro-8.jpg', type: 'image'},
        {src: 'oro/oro-9.jpg', type: 'image'},
        {src: 'oro/oro-10.jpg', type: 'image'},
        {src: 'oro/oro-11.jpg', type: 'image'},
        {src: 'oro/oro-12.jpg', type: 'image'},
        {src: 'oro/oro-13.jpg', type: 'image'},
        {src: 'oro/oro-14.jpg', type: 'image'},
        {src: 'https://player.vimeo.com/progressive_redirect/playback/792133491/rendition/720p/file.mp4?loc=external&signature=6ee82a5bebfa008ad17fb76658ea393e1f30afabfa6922fec8e13539feb3689d', type: 'mp4'},
        {src: 'oro/oro-16.jpg', type: 'image'},

    ]

    export default OroData
const BornAndRaisedData = [
    {src: 'b&r/b&r-1.jpg', type: 'image'},
    {src: 'b&r/b&r-2.jpg', type: 'image'},
    {src: 'b&r/b&r-3.jpg', type: 'image'},
    {src: 'b&r/b&r-4.jpg', type: 'image'},
    {src: 'b&r/b&r-5.jpg', type: 'image'},
    {src: 'b&r/b&r-6.jpg', type: 'image'},
    {src: 'b&r/b&r-7.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792132239/rendition/720p/file.mp4?loc=external&signature=6e69a467fe80482220787d6393da643f71419528015ed62dd82355c926ec04f8', type: 'mp4'},
    {src: 'b&r/b&r-9.jpg', type: 'image'},
    {src: 'b&r/b&r-10.jpg', type: 'image'},
    {src: 'b&r/b&r-11.jpg', type: 'image'},
    {src: 'b&r/b&r-12.jpg', type: 'image'},
    {src: 'b&r/b&r-13.jpg', type: 'image'},
    {src: 'b&r/b&r-14.jpg', type: 'image'},
    {src: 'b&r/b&r-15.jpg', type: 'image'},
    {src: 'b&r/b&r-16.jpg', type: 'image'},
    {src: 'b&r/b&r-17.jpg', type: 'image'},
    {src: 'b&r/b&r-18.jpg', type: 'image'},
    {src: 'b&r/b&r-19.jpg', type: 'image'},
    {src: 'b&r/b&r-20.jpg', type: 'image'},
    {src: 'b&r/b&r-21.jpg', type: 'image'},
    {src: 'b&r/b&r-22.jpg', type: 'image'},
    {src: 'b&r/b&r-23.jpg', type: 'image'},
]

export default BornAndRaisedData
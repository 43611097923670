const BeautifulAshesData = [
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792875240/rendition/720p/file.mp4?loc=external&signature=81581060935c0333d247b0f1dac3fb5baca03a284833badb44b3febd5bd0b193', type: 'mp4'},
    {src: 'beautiful-ashes/beautiful-ashes-2.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-3.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-4.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-5.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-6.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792875612/rendition/720p/file.mp4?loc=external&signature=0bd64a4c630db136a0ee4cdbf29c925b94d82a2334c563b712542049b91e3cc5', type: 'mp4'},
    {src: 'beautiful-ashes/beautiful-ashes-8.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-9.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-10.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-11.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-12.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-13.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792875634/rendition/720p/file.mp4?loc=external&signature=1c40952ea1ac812669bf77081b447be48ff9b53ef1bdf16ea08150df1a41728d', type: 'mp4'},
    {src: 'beautiful-ashes/beautiful-ashes-15.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-16.jpg', type: 'image'},
    {src: 'beautiful-ashes/beautiful-ashes-17.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792876780/rendition/720p/file.mp4?loc=external&signature=d6b32970184e465786f5d99787005d603ea22c0497ec9cc2350be43b1ddf8372', type: 'mp4'}
]

export default BeautifulAshesData
const Formula1Data = [
    {src: 'formula1/formula1-1.jpg', type: 'image'},
    {src: 'formula1/formula1-2.jpg', type: 'image'},
    {src: 'formula1/formula1-3.jpg', type: 'image'},
    {src: 'formula1/formula1-4.jpg', type: 'image'},
    {src: 'formula1/formula1-5.jpg', type: 'image'},
    {src: 'formula1/formula1-6.jpg', type: 'image'},
    {src: 'formula1/formula1-7.jpg', type: 'image'},
    {src: 'formula1/formula1-8.jpg', type: 'image'},
    {src: 'formula1/formula1-9.jpg', type: 'image'},
    {src: 'formula1/formula1-10.jpg', type: 'image'},
    {src: 'formula1/formula1-11.jpg', type: 'image'},
    {src: 'formula1/formula1-12.jpg', type: 'image'},
    {src: 'formula1/formula1-13.jpg', type: 'image'},
    {src: 'formula1/formula1-14.jpg', type: 'image'},
    {src: 'formula1/formula1-15.jpg', type: 'image'},
    {src: 'formula1/formula1-16.jpg', type: 'image'},
    {src: 'formula1/formula1-17.jpg', type: 'image'},
    {src: 'formula1/formula1-18.jpg', type: 'image'},
    {src: 'formula1/formula1-19.jpg', type: 'image'}
]

export default Formula1Data
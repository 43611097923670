const AshleyEckhoffData = [
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792655779/rendition/720p/file.mp4?loc=external&signature=76bb2f839bdf3e8e7d3d0f254b545969590ab344495986b053495b9281716545', type: 'mp4'},
    {src: 'ashley-eckhoff/ashley-eckhoff-2.jpg', type: 'image'},
    {src: 'ashley-eckhoff/ashley-eckhoff-3.jpg', type: 'image'},
    {src: 'ashley-eckhoff/ashley-eckhoff-4.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792655736/rendition/720p/file.mp4?loc=external&signature=52affbeb94711d16406bbfda0ff5d64dffd4ab86a5b9f903c074ab784c903517', type: 'mp4'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792655824/rendition/720p/file.mp4?loc=external&signature=5ac2f1e221a53ced9ad6e95597d245da0467ca3ff20998d2b689716a16654b14', type: 'mp4'},
    {src: 'ashley-eckhoff/ashley-eckhoff-7.jpg', type: 'image'},
    {src: 'ashley-eckhoff/ashley-eckhoff-8.jpg', type: 'image'},
    {src: 'ashley-eckhoff/ashley-eckhoff-9.jpg', type: 'image'},
    {src: 'ashley-eckhoff/ashley-eckhoff-10.jpg', type: 'image'},
    {src: 'ashley-eckhoff/ashley-eckhoff-11.jpg', type: 'image'},
    {src: 'ashley-eckhoff/ashley-eckhoff-12.jpg', type: 'image'}
]

export default AshleyEckhoffData
import React, {useEffect, useState} from 'react'
import SliderWrapper from '../../service/SliderWrapper'

import {AthleticGainesData, AthleticGainesDataMob} from './_data'
import projectGallery from '../../service/ProjectGallery'

const Index = () => {
    const [mobileSlider, setMobileSlider] = useState(false)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.innerWidth <= 1023.8 ? setMobileSlider(true) : setMobileSlider(false)
        }
    },[mobileSlider])

    return (
        <SliderWrapper>
            {
                mobileSlider ? (
                    projectGallery(AthleticGainesDataMob)
                ) : (
                    projectGallery(AthleticGainesData)
                )
            }
        </SliderWrapper>
    )
}

export default Index

const BlrData = [
    {src: 'blr/blrs-1.jpg', type: 'image'},
    {src: 'blr/blrs-2.jpg', type: 'image'},
    {src: 'blr/blrs-3.jpg', type: 'image'},
    {src: 'blr/blrs-4.jpg', type: 'image'},
    {src: 'blr/blrs-5.jpg', type: 'image'},
    {src: 'blr/blrs-6.jpg', type: 'image'},
    {src: 'blr/blrs-7.jpg', type: 'image'},
    {src: 'blr/blrs-8.jpg', type: 'image'},
    {src: 'blr/blrs-9.jpg', type: 'image'},
    {src: 'blr/blrs-10.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/801178048/rendition/1080p/file.mp4?loc=external&signature=33e6e6edede921ba25c83751d49af4a71791c6eef061677b7b36fc5f363d13f9', type: 'mp4'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/801178101/rendition/1080p/file.mp4?loc=external&signature=4131fbf3f511dc7ca0ff1262ca45f1767b9ce1d48557878047e694b4566ae166', type: 'mp4'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/801178190/rendition/1080p/file.mp4?loc=external&signature=cb0a6f6873df22dfe0aaf12512997bf9f7e50c1731e4416b92d1a44a7dd978b3', type: 'mp4'},
    {src: 'blr/blrs-11.jpg', type: 'image'},
    {src: 'blr/blrs-12.jpg', type: 'image'}
]

export default BlrData
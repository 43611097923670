const NoxxData = [
    {src: 'noxx/noxx-1.jpg', type: 'image'},
    {src: 'noxx/noxx-2.jpg', type: 'image'},
    {src: 'noxx/noxx-3.jpg', type: 'image'},
    {src: 'noxx/noxx-4.jpg', type: 'image'},
    {src: 'noxx/noxx-5.jpg', type: 'image'},
    {src: 'noxx/noxx-6.jpg', type: 'image'},
    {src: 'noxx/noxx-7.jpg', type: 'image'},
    {src: 'noxx/noxx-8.jpg', type: 'image'},
    {src: 'noxx/noxx-9.jpg', type: 'image'},
    {src: 'noxx/noxx-10.jpg', type: 'image'},
    {src: 'noxx/noxx-11.jpg', type: 'image'},
    {src: 'noxx/noxx-12.jpg', type: 'image'},
    {src: 'noxx/noxx-13.jpg', type: 'image'},
    {src: 'noxx/noxx-14.jpg', type: 'image'},
    {src: 'noxx/noxx-15.jpg', type: 'image'},
    {src: 'noxx/noxx-16.jpg', type: 'image'},
    {src: 'noxx/noxx-17.jpg', type: 'image'}
]

export default NoxxData
import React from 'react'
import SliderWrapper from '../../service/SliderWrapper'

import BeautifulAshesData from './_data'
import projectGallery from '../../service/ProjectGallery'

const BeautifulAshes = () => {
    return (
        <SliderWrapper>
            {projectGallery(BeautifulAshesData)}
        </SliderWrapper>
    )
}

export default BeautifulAshes
const ScoopData = [
    {src: 'scoop/scoop-1.jpg', type: 'image'},
    {src: 'scoop/scoop-2.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792261592/rendition/720p/file.mp4?loc=external&signature=5ee9dfd60545be81551333429b5bf313bd3086dbc798faa47b6ad2caad4eb518', type: 'mp4'},
    {src: 'scoop/scoop-4.jpg', type: 'image'},
    {src: 'scoop/scoop-5.jpg', type: 'image'},
    {src: 'scoop/scoop-6.jpg', type: 'image'},
    {src: 'scoop/scoop-7.jpg', type: 'image'},
    {src: 'scoop/scoop-8.jpg', type: 'image'},
    {src: 'scoop/scoop-9.jpg', type: 'image'},
    {src: 'scoop/scoop-10.jpg', type: 'image'},
    {src: 'scoop/scoop-11.jpg', type: 'image'},
    {src: 'scoop/scoop-12.jpg', type: 'image'},
    {src: 'scoop/scoop-13.jpg', type: 'image'},
    {src: 'scoop/scoop-14.jpg', type: 'image'},
    {src: 'scoop/scoop-15.jpg', type: 'image'},
    {src: 'scoop/scoop-16.jpg', type: 'image'},
    {src: 'scoop/scoop-17.jpg', type: 'image'},
    {src: 'scoop/scoop-18.jpg', type: 'image'},
    {src: 'scoop/scoop-19.jpg', type: 'image'},
    {src: 'scoop/scoop-20.jpg', type: 'image'},
]

export default ScoopData
import React from 'react'
import SliderWrapper from '../../service/SliderWrapper'

import LeGoffData from './_data'
import projectGallery from '../../service/ProjectGallery'

const LeGoff = () => {
    return (
        <SliderWrapper>
            {projectGallery(LeGoffData)}
        </SliderWrapper>
    )
}

export default LeGoff
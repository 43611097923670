const KurData = [
    { src: 'https://player.vimeo.com/progressive_redirect/playback/792252517/rendition/720p/file.mp4?loc=external&signature=1b3f07fd01a2f0a0cfefc3f4c366a7788cde140ee92e3e780ca3d369c873cffd', type: 'mp4' },
    { src: 'kur/kur-2.jpg', type: 'image' },
    { src: 'kur/kur-3.jpg', type: 'image' },
    { src: 'https://player.vimeo.com/progressive_redirect/playback/792252547/rendition/720p/file.mp4?loc=external&signature=a6f6fb1dfdb8a60b3c88c4e0e56be53139e5891ba861ebe30642fb655ad87dbe', type: 'mp4' },
    { src: 'kur/kur-5.jpg', type: 'image' },
    { src: 'kur/kur-6.jpg', type: 'image' },
    { src: 'kur/kur-7.jpg', type: 'image' },
    { src: 'kur/kur-8.jpg', type: 'image' },
    { src: 'kur/kur-9.jpg', type: 'image' },
    { src: 'kur/kur-10.jpg', type: 'image' },
    { src: 'kur/kur-11.jpg', type: 'image' },
    { src: 'kur/kur-12.jpg', type: 'image' },
    { src: 'kur/kur-13.jpg', type: 'image' },
    { src: 'kur/kur-14.jpg', type: 'image' },
    { src: 'kur/kur-15.jpg', type: 'image' },
    { src: 'kur/kur-16.jpg', type: 'image' },
    { src: 'kur/kur-17.jpg', type: 'image' },
    { src: 'kur/kur-18.jpg', type: 'image' }
]

export default KurData


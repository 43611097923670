const AwolData = [
    {src: 'awol/awol-1.jpg', type: 'image'},
    {src: 'awol/awol-2.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792644692/rendition/720p/file.mp4?loc=external&signature=17aba4856a49d090d7e383af6886aae13cf7596d3ecf48bc32be497f0b84a180', type:'mp4'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792644739/rendition/720p/file.mp4?loc=external&signature=6a46b3168cef0fd946cec6beb774295e93c39898521717356e3119b2eb5e9356', type:'mp4'},
    {src: 'awol/awol-5.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792644771/rendition/720p/file.mp4?loc=external&signature=78619648aef30ca29467344c15999c752b6ec8c98d45530ed4e8b6776acbad7d', type:'mp4'},
    {src: 'awol/awol-7.jpg', type: 'image'},
    {src: 'awol/awol-8.jpg', type: 'image'},
    {src: 'awol/awol-9.jpg', type: 'image'},
    {src: 'awol/awol-10.jpg', type: 'image'},
    {src: 'awol/awol-11.jpg', type: 'image'},
    {src: 'awol/awol-12.jpg', type: 'image'},
    {src: 'awol/awol-13.jpg', type: 'image'},
    {src: 'awol/awol-14.jpg', type: 'image'},
    {src: 'awol/awol-15.jpg', type: 'image'},
    {src: 'awol/awol-16.jpg', type: 'image'},
    {src: 'awol/awol-17.jpg', type: 'image'},
    {src: 'awol/awol-18.jpg', type: 'image'},
    {src: 'awol/awol-19.jpg', type: 'image'},
    {src: 'awol/awol-20.jpg', type: 'image'},
    {src: 'awol/awol-21.jpg', type: 'image'},
]

export default AwolData
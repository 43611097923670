import React from 'react'
import {SwiperSlide} from 'swiper/react'

import Image from '../../../elements/Image'

const projectGallery = array => {
    return array.map((item, index) => {
        if (typeof window !== 'undefined' && window.innerWidth <= 1023.8) {
            if (item.type === 'image') {
                return (
                    <div className="library-slider-item" key={`mobile-slide-${index}`}>
                        <Image src={`/images/library/projects/${item.src}`} alt="" width={1578} height={888}/>
                    </div>
                )
            } else if (item.type === 'mp4') {
                return (
                    <div className="library-slider-item" key={`mobile-video-${index}`}>
                        <video
                            autoPlay
                            loop
                            playsInline
                            width="100%"
                            height="100%"
                            muted
                        >
                            <source src={item.src} type={'video/mp4'}/>
                        </video>
                    </div>
                )
            }
        } else {
            if (item.type === 'image') {
                return (
                    <SwiperSlide key={`slide-image-${index}`}>
                        <div className="library-slider-item" data-swiper-parallax="50%">
                            <Image src={`/images/library/projects/${item.src}`} alt="" width={1578} height={888}/>
                        </div>
                    </SwiperSlide>
                )
            } else if (item.type === 'mp4') {
                return (
                    <SwiperSlide key={`slide-video-${index}`}>
                        <div className="library-slider-item" data-swiper-parallax="50%">
                            <video
                                autoPlay
                                loop
                                playsInline
                                width="100%"
                                height="100%"
                                muted
                            >
                                <source src={item.src} type={'video/mp4'}/>
                            </video>
                        </div>
                    </SwiperSlide>
                )
            }
        }
        return array
    })
}

export default projectGallery

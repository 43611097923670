import React, {useEffect, useState, useRef} from 'react'
import Image from '../../elements/Image'
import {StaticImage} from 'gatsby-plugin-image';
import Video from '../../elements/Video';

const Shelf = ({animationClass, bookSlides, bookEvent, shelfLeft, shelfRight,}) => {
    const [drag, setDrag] = useState(false)
    const [nextOption, setNextOption] = useState(false)
    const [prevOption, setPrevOption] = useState(false)
    const [selectedBook, setSelectedBook] = useState(null)
    const [hasOppenedBook, setHasOppenedBook] = useState(false)
    const [previewBook, setPreviewBook] = useState(false)
    const [mousePosition, setMousePosition] = useState({
        top: 0,
        left: 0,
        opacity: 0,
    })
    const [hideCursor, setHideCursor] = useState(false)
    const cursorWrapper = useRef(null)
    const cursor = useRef(null)

    useEffect(() => {
        grabBooks()
    }, [previewBook])

    useEffect(() => {
        let wrapper = document.querySelector('.library__shelf--slider')
        let nextArrow = document.querySelector('.slide-right')
        let prevArrow = document.querySelector('.slide-left')

        const scrollSlider = () => {
            let newScrollLeft = wrapper.scrollLeft,
                lastStop = wrapper.querySelectorAll('.library__book--cover'),
                lastArray = lastStop[lastStop.length - 1]

            let observer = new IntersectionObserver(([entry]) => {
                if (entry.intersectionRatio > 0.1) {
                    setNextOption(true)
                } else {
                    setNextOption(false)
                }
            }, {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            })

            observer.observe(lastArray)

            newScrollLeft === 0 ? setPrevOption(false) : setPrevOption(true)
        }

        const scrollToRight = () => {
            const scrollWidth = wrapper.querySelector('.library__book--cover:first-child').clientWidth
            return wrapper.scrollLeft += scrollWidth
        }

        const scrollToLeft = () => {
            const scrollWidth = wrapper.querySelector('.library__book--cover:first-child').clientWidth
            return wrapper.scrollLeft -= scrollWidth
        }

        wrapper.addEventListener('scroll', scrollSlider)
        nextArrow.addEventListener('click', scrollToRight)
        prevArrow.addEventListener('click', scrollToLeft)

        return () => {
            wrapper.removeEventListener('scroll', scrollSlider)
            nextArrow.removeEventListener('click', scrollToRight)
            prevArrow.removeEventListener('click', scrollToRight)
        }

    }, [])


    useEffect(() => {
        const convertVWToPx = (vw) => {
            return vw * (document.documentElement.clientWidth / 100 );
        }
        
        const allShelf = [...shelfLeft, ...shelfRight]
        const findBehindOppenedBook = allShelf.findIndex(item => item.id === hasOppenedBook)
        const findCurrentOppenedBook = allShelf.findIndex(item => item.id === selectedBook)

        const getActiveBook = () => {
            const activeBook = document.querySelector('.selectedBook')
            const scrollContainer = document.querySelector('.library__shelf--slider')
            const scrollWidth = scrollContainer.querySelector('.library__book--cover:first-child').clientWidth
            const isPhone = window.innerWidth <= 1023.8
            const isTablet = window.innerWidth >= 1024 && window.innerWidth <= 1279.8 ? 555 : convertVWToPx(30)

            const condition = findBehindOppenedBook > findCurrentOppenedBook && hasOppenedBook && findBehindOppenedBook !== -1
            const activeBookLeft = condition
                ? activeBook.getBoundingClientRect().left
                : activeBook.getBoundingClientRect().left - isTablet
            const activeBookWidth = activeBook.getBoundingClientRect().width + isTablet
            const relativeOfLeft = activeBookLeft + (activeBookWidth / 2)
            const scrollToLeft = !hasOppenedBook ? relativeOfLeft - (window.innerWidth / 2) + isTablet : relativeOfLeft - (window.innerWidth / 2)
            const scrollToRight = !hasOppenedBook ? (window.innerWidth / 2) - relativeOfLeft - isTablet : (window.innerWidth / 2) - relativeOfLeft
            
            const sum1 = scrollContainer.scrollLeft + scrollToLeft + (scrollWidth / 2)
            const sum2 = scrollContainer.scrollLeft - scrollToRight + (scrollWidth / 2)
            const smallScreenSum1 =
                !isPhone
                ? sum1
                : !hasOppenedBook
                ? scrollContainer.scrollLeft + scrollToLeft + scrollWidth
                : findBehindOppenedBook === 0
                ? scrollContainer.scrollLeft + scrollToLeft - convertVWToPx(15)
                : scrollContainer.scrollLeft + scrollToLeft + scrollWidth - convertVWToPx(30)
            const smallScreenSum2 = !isPhone ? sum2 : scrollContainer.scrollLeft - scrollToRight + scrollWidth
            
            if (smallScreenSum1 > 0) {
                if(findCurrentOppenedBook >= 15 || !hasOppenedBook) {
                    setTimeout(() => scrollContainer.scrollTo({top: 0, left: smallScreenSum1, behavior: "smooth"}), 300)
                } else {
                    scrollContainer.scrollTo({top: 0, left: smallScreenSum1, behavior: "smooth"})
                }
            } else {
                scrollContainer.scrollTo({top: 0, left: smallScreenSum2, behavior: "smooth"})
            }
        }

        if (selectedBook) {
            getActiveBook()
        }
    },[selectedBook])

    const updateMouse = e => {
        setMousePosition({
            top: e.clientY - 10 + 'px',
            left: e.clientX - 10 + 'px',
            opacity: 1,
        })

        let wrapper = cursorWrapper?.current.getBoundingClientRect()
        let cursorElem = cursor?.current.getBoundingClientRect()

        if (cursorElem.right === wrapper.right) {
            setHideCursor(true)
        }
    }

    const grabBooks = () => {
        const slider = document.querySelector('.library__shelf--slider')
        if (!previewBook && slider) {
            let isDown = false
            let startX
            let scrollLeft

            slider.addEventListener('mousedown', e => {
                isDown = true
                slider.classList.add('active')
                startX = e.pageX - slider.offsetLeft
                scrollLeft = slider.scrollLeft
            })
            slider.addEventListener('mouseleave', () => {
                isDown = false
                slider.classList.remove('active')
            })
            slider.addEventListener('mouseup', () => {
                isDown = false
                slider.classList.remove('active')
                setDrag(false)
            })
            slider.addEventListener('mousemove', e => {
                if (!isDown) return
                e.preventDefault()
                const x = e.pageX - slider.offsetLeft
                const walk = (x - startX) * 3 //scroll-fast
                slider.scrollLeft = scrollLeft - walk
                setDrag(true)
            })
        }
    }

    const openPrev = () => {
        setTimeout(() => {
            setPreviewBook(true)
        }, 100)
    }

    return (
        <section
            className={`library__shelf${
                animationClass ? ' library__shelf--open' : ''
            }`}
        >
            <StaticImage
                src="../../../../static/images/library/shelf/shelf-top.png"
                className="library__shelf--top"
                alt="Shelf top"
                placeholder="blurred"
                quality="100"
                objectFit="unset"
                formats={['auto', 'webp', 'avif']}
            />
            <div className="library__shelf--books">
                <div className={`slide-left${prevOption || animationClass ? ' show-arrow' : ''}`}>
                    <img src="/svgs/chevron-left.svg" alt="chev-left"/>
                </div>
                <div className={`slide-right${nextOption || animationClass ? ' hide-arrow' : ''}`}>
                    <img src="/svgs/chevron-right.svg" alt="chev-right"/>
                </div>
                <div className="library__shelf--slider">
                    <div className={`library__shelf--left${animationClass ? ' open--left' : ''}${drag ? ' dragging' : ''}`}>
                        {shelfLeft.map((book, i) => {
                            return (
                                <div key={`book-cover-${book.id}`}
                                     onClick={() => {
                                         setHasOppenedBook(selectedBook ? selectedBook : null)
                                         setSelectedBook(prev => (prev === book.id ? null : book.id))
                                         openPrev()
                                     }}
                                     role="presentation"
                                     className={`library__book--cover${selectedBook === book.id ? ' selectedBook' : ''}`}>
                                    <span>
                                      <img src={`/images/library/shelf/${book.img}.png`} alt=""/>
                                    </span>
                                    <div className={`library__shelf--preview`}
                                         onClick={() => {
                                             setSelectedBook(null)
                                             setHasOppenedBook(null)
                                             bookEvent(book.id)
                                         }}
                                         role="presentation"
                                         onMouseMove={updateMouse}
                                         ref={cursorWrapper}
                                    >
                                        <figure
                                            className={`preview-container${selectedBook === book.id && previewBook ? ' preview-open' : ''}`}
                                            onMouseLeave={() => setHideCursor(true)}
                                            onMouseEnter={() => setHideCursor(false)}
                                            role="presentation"
                                        >
                                            <div className="preview-media">
                                                <div className="preview-media__inner">
                                                    {book.previewType === 'video' ? (
                                                        <Video
                                                            src={book.preview}
                                                            autoPlay={true}
                                                            loop={true}
                                                            type="mp4"
                                                        />
                                                    ) : book.previewType === 'image' ? (
                                                        <Image src={book.preview} alt={book.title} />
                                                    ) : (
                                                        <img src={'/images/home/blr$-preview.jpg'} alt=""/>
                                                    )}
                                                </div>
                                            </div>
                                            <figcaption>
                                                <h3>{book.title}</h3>
                                                <p>{book.description}</p>
                                                <div className="preview-category">
                                                    <ul className="preview-list">
                                                        {book.categories.map((categ, index) => {
                                                            return (
                                                                <li key={`project-category-${index}`} className="preview-list-item">
                                                                    <p>{categ.category}</p>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </figcaption>
                                        </figure>
                                        <span className={`text-cursor${hideCursor ? ' hide-cursor' : ''}`}
                                              ref={cursor} style={mousePosition}
                                        >
                                            VIEW PROJECT
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className={`library__shelf--right${animationClass ? ' open--right' : ''}${drag ? ' dragging' : ''}`}>
                        {shelfRight.map((book, i) => {
                            return (
                                <div
                                    role="presentation"
                                    key={`shelf-right-${book.id}`}
                                    onClick={() => {
                                        setHasOppenedBook(selectedBook ? selectedBook : null)
                                        setSelectedBook(prev => (prev === book.id ? null : book.id))
                                        openPrev()
                                    }}
                                    className={`library__book--cover${selectedBook === book.id ? ' selectedBook' : ''}`}
                                >
                                    <span role="presentation">
                                      <img src={`/images/library/shelf/${book.img}.png`} alt="" role="presentation"/>
                                    </span>
                                    <div
                                        className={`library__shelf--preview`}
                                        onClick={() => {
                                            setSelectedBook(null)
                                            setHasOppenedBook(null)
                                            bookEvent(book.id)
                                        }}
                                        role="presentation"
                                        onMouseMove={updateMouse}
                                        ref={cursorWrapper}
                                    >
                                        <figure
                                            role="presentation"
                                            className={`preview-container${selectedBook === book.id && previewBook ? ' preview-open' : ''}`}
                                            onMouseLeave={() => setHideCursor(true)}
                                            onMouseEnter={() => setHideCursor(false)}
                                        >
                                            <div className="preview-media">
                                                <div className="preview-media__inner">
                                                    {book.previewType === 'video' ? (
                                                        <Video
                                                            src={book.preview}
                                                            autoPlay={true}
                                                            loop={true}
                                                            type="mp4"
                                                        />
                                                    ) : book.previewType === 'image' ? (
                                                        <Image src={book.preview} alt={book.title} />
                                                    ) : (
                                                        <img src={'/images/home/blr$-preview.jpg'} alt=""/>
                                                    )}
                                                </div>
                                            </div>
                                            <figcaption>
                                                <h3>{book.title}</h3>
                                                <p>{book.description}</p>
                                                <div className="preview-category">
                                                    <ul className="preview-list">
                                                        {book.categories.map((category, ind) => {
                                                            return (
                                                                <li key={`category-${ind}`} className="preview-list-item">
                                                                    {category.category}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </figcaption>
                                        </figure>
                                        <span ref={cursor} style={mousePosition}
                                              className={`text-cursor${hideCursor ? ' hide-cursor' : ''}`}>
                                            VIEW PROJECT
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <StaticImage src="../../../../static/images/library/shelf/shelf-bottom.png"
                         className="library__shelf--bottom"
                         alt="Shelf bottom"
                         placeholder="blurred"
                         quality="100"
                         objectFit="unset"
                         formats={['auto', 'webp', 'avif']}
            />
        </section>
    )
}

export default Shelf
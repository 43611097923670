const LeGoffData = [
    {src: 'https://player.vimeo.com/progressive_redirect/playback/792871556/rendition/720p/file.mp4?loc=external&signature=12e8bf9e87793625dca7998e9023ee11a3d881565773b6833a468152b5152463', type: 'mp4'},
    {src: 'le-goff/le-goff-2.jpg', type: 'image'},
    {src: 'le-goff/le-goff-3.jpg', type: 'image'},
    {src: 'le-goff/le-goff-4.jpg', type: 'image'},
    {src: 'le-goff/le-goff-5.jpg', type: 'image'},
    {src: 'le-goff/le-goff-6.jpg', type: 'image'},
    {src: 'le-goff/le-goff-7.jpg', type: 'image'},
    {src: 'le-goff/le-goff-8.jpg', type: 'image'},
    {src: 'le-goff/le-goff-9.jpg', type: 'image'},
    {src: 'le-goff/le-goff-10.jpg', type: 'image'},
    {src: 'le-goff/le-goff-11.jpg', type: 'image'},
    {src: 'le-goff/le-goff-12.jpg', type: 'image'},
    {src: 'le-goff/le-goff-13.jpg', type: 'image'},
    {src: 'le-goff/le-goff-14.jpg', type: 'image'},
    {src: 'le-goff/le-goff-15.jpg', type: 'image'},
]

export default LeGoffData
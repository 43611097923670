import React from 'react'
import SliderWrapper from '../../service/SliderWrapper';

import BlocksData from './_data';
import projectGallery from '../../service/ProjectGallery';

const Index = () => {
    return(
        <SliderWrapper>
            {projectGallery(BlocksData)}
        </SliderWrapper>
    )
}

export default Index
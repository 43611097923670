import React, {useState, useEffect, useRef} from 'react'
import {useLocation, navigate} from '@reach/router'
import Layout from '../../../layouts/Layout';

import {books, bookTitles} from './data/shelf-books'
import SafariDetect from '../../elements/SafariDetect'
import Image from '../../elements/Image'

import Shelf from './Shelf'

import Blocks from './books/Blocks'
import Formula1 from './books/Formula1'
import BornAndRaised from './books/BornAndRaised'
import Oro from './books/Oro'
import Blr from './books/Blr'
import Kur from './books/Kur'
import Scoop from './books/Scoop'
import AthleticGaines from './books/AthleticGaines'
import Awol from './books/Awol'
import AshleyEckhoff from './books/AshleyEckhoff'
import LeGoff from './books/LeGoff'
import Noxx from './books/Noxx'
import BeautifulAshes from './books/BeautifulAshes'

const Library = () => {
    const [switchComponent, setSwitchComponent] = useState(0)
    const [shelfOpen, setShelfOpen] = useState(false)
    const [closePage, setClosePage] = useState(false)
    const [animationClass, setAnimationClass] = useState(false)
    const [nextBook, setNextBook] = useState(false)
    const [shelfLeft, setShelfLeft] = useState([])
    const [shelfRight, setShelfRight] = useState([])
    const [nextBookTitle, setNextBookTitle] = useState(0)
    const bookSlides = useRef(null)
    const location = useLocation()

    useEffect(() => {
        if (!switchComponent) {
            setShelfOpen(false)
        }
    }, [switchComponent])

    useEffect(() => {
        const index = Math.round(books.length / 2)
        setShelfLeft(books.slice(0, books.indexOf(books[index])))
        setShelfRight(books.slice(books.indexOf(books[index])))
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        locationTriger()
        if(!location.hash) {
            closeBook()
        }
    }, [location.hash])

    useEffect(() => {
        if (switchComponent > 0 && switchComponent < bookTitles.length) {
            setNextBookTitle(
                bookTitles.find(item => item.id === switchComponent + 1)?.title
            )
        }
    }, [switchComponent])
    
    const bookEvent = id => {
        if (switchComponent === 0) {
            const item = books.find(i => i.id === id)
            const index = books.indexOf(item)
            setShelfLeft(books.slice(0, index + 1))
            setShelfRight(books.slice(index + 1))
            setAnimationClass(true)
            window.location.hash = item.hash
            showComponent(id)
            setTimeout(() => {
                setShelfOpen(true)
            }, 2000)
        }
    }

    const closeBook = () => {
        setShelfOpen(false)
        setTimeout(() => {
            setClosePage(false)
            setAnimationClass(false)
        }, 400)
        setTimeout(() => {
            setSwitchComponent(0)
        }, 2500)
    }

    const showComponent = id => {
        setSwitchComponent(id)
        setClosePage(true)
    }

    const nextProject = () => {
        setNextBook(true)
        setTimeout(() => {
            setSwitchComponent(prev => prev + 1)
            setNextBook(false)
        }, 1200)
    }

    const locationTriger = () => {
        const bookFromHash = books.find((book) => book.hash === location.hash)
        bookFromHash && bookEvent(bookFromHash.id)
    }
    
    return (
        <Layout
            pageClass={`bright-header library__main ${
                closePage ? 'library__book--open' : ''
            }`}
        >
            <SafariDetect>
                <Shelf
                    bookSlides={bookSlides}
                    animationClass={animationClass}
                    bookEvent={bookEvent}
                    shelfLeft={shelfLeft}
                    shelfRight={shelfRight}
                />
            </SafariDetect>
            <section className="library__book--pages">
                <div className={`library__book--container ${nextBook ? 'next' : ''}`}>
                    <button
                        className={`library__back ${
                            shelfOpen ? 'library__back--open' : ''
                        }`}
                        onClick={() => {
                            closeBook()
                            navigate(
                                `/library`,
                            )
                        } }
                    >
                        <Image src="/svgs/chevron-right.svg" alt="icon"/>
                        Library
                    </button>
                    <button
                        className={`library__book--next ${
                            shelfOpen && switchComponent !== bookTitles.length ? 'active' : ''
                        }`}
                        onClick={nextProject}
                    >
                        <p>
                            NEXT PROJECT
                            <Image src="/svgs/arrow-next.svg" alt="icon"/>
                        </p>
                        <h3
                            className={`library__book--next-title ${
                                shelfOpen ? 'active' : ''
                            }`}
                        >
                            {nextBookTitle}
                        </h3>
                    </button>
                    {switchComponent === 1 ? (
                        <Blocks/>
                    ) : switchComponent === 2 ? (
                        <Formula1/>
                    ) : switchComponent === 3 ? (
                        <BornAndRaised/>
                    ) : switchComponent === 4 ? (
                        <Oro/>
                    ) : switchComponent === 5 ? (
                        <Blr/>
                    ) : switchComponent === 6 ? (
                        <Kur/>
                    ) : switchComponent === 7 ? (
                        <Scoop/>
                    ) : switchComponent === 8 ? (
                        <AthleticGaines/>
                    ) : switchComponent === 9 ? (
                        <Awol/>
                    ) : switchComponent === 10 ? (
                        <AshleyEckhoff/>
                    ) : switchComponent === 11 ? (
                        <LeGoff/>
                    ) : switchComponent === 12 ? (
                        <Noxx/>
                    ) : switchComponent === 13 ? (
                        <BeautifulAshes/>
                    )  : (
                        <></>
                    )}
                </div>
            </section>
        </Layout>
    )
}

export default Library

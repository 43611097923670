import React from 'react'
import SliderWrapper from '../../service/SliderWrapper'

import NoxxData from './_data'
import projectGallery from '../../service/ProjectGallery'

const Index = () => {

    return (
        <SliderWrapper>
            {projectGallery(NoxxData)}
        </SliderWrapper>
    )
}

export default Index

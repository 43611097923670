import React, {useState, useEffect} from 'react'
import {Swiper} from 'swiper/react'
import {Mousewheel, Parallax, Navigation} from 'swiper'

const SliderWrapper = props => {
    const {
        children
    } = props

    const [mobileProjects, setMobileProjects] = useState(false)

    useEffect(() => {
       if (typeof window !== 'undefined') {
          window.innerWidth <= 1023.8 ? setMobileProjects(true) : setMobileProjects(false)
       }
    },[])

    return (
        <div className="library-slider-wrapper">
            {mobileProjects ? (
                <>
                    {children}
                </>
            ) : (
                <Swiper
                    navigation={true}
                    loop={true}
                    speed={1500}
                    grabCursor={true}
                    mousewheel={true}
                    parallax={true}
                    modules={[Parallax, Mousewheel, Navigation]}
                >
                    {children}
                </Swiper>
            )}
        </div>
    )
}

export default SliderWrapper
const AthleticGainesData = [
    {src: 'athletic-gaines/athletic-gaines-1.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-2.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-3.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-4.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-5.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-6.jpg', type: 'image'},
    // {src: 'https://player.vimeo.com/progressive_redirect/playback/767186935/rendition/720p/file.mp4?loc=external&signature=6b4d5b922aba87a5a3119844c64216ed3a49289445a6201fa2be9feecdf7e4d9', type: 'mp4'},
    {src: 'athletic-gaines/athletic-gaines-8.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-9.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-10.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-11.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-12.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-13.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-14.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-15.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-16.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-17.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-18.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-19.jpg', type: 'image'}
]

const AthleticGainesDataMob = [
    {src: 'athletic-gaines/athletic-gaines-1.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-2.jpg', type: 'image'},
    {src: 'athletic-gaines/mobile/Athletic-Gaines-Web-Images-3A.jpg', type: 'image'},
    {src: 'athletic-gaines/mobile/Athletic-Gaines-Web-Images-3B.jpg', type: 'image'},
    {src: 'athletic-gaines/mobile/Athletic-Gaines-Web-Images-4A.jpg', type: 'image'},
    {src: 'athletic-gaines/mobile/Athletic-Gaines-Web-Images-4B.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-5.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-6.jpg', type: 'image'},
    // {src: 'https://player.vimeo.com/progressive_redirect/playback/767186935/rendition/720p/file.mp4?loc=external&signature=6b4d5b922aba87a5a3119844c64216ed3a49289445a6201fa2be9feecdf7e4d9', type: 'mp4'},
    {src: 'athletic-gaines/athletic-gaines-8.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-9.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-10.jpg', type: 'image'},
    {src: 'athletic-gaines/mobile/Athletic-Gaines-Web-Images-11A.jpg', type: 'image'},
    {src: 'athletic-gaines/mobile/Athletic-Gaines-Web-Images-11B.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-12.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-13.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-14.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-15.jpg', type: 'image'},
    {src: 'athletic-gaines/mobile/Athletic-Gaines-Web-Images-16A.jpg', type: 'image'},
    {src: 'athletic-gaines/mobile/Athletic-Gaines-Web-Images-16B.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-17.jpg', type: 'image'},
    {src: 'athletic-gaines/athletic-gaines-18.jpg', type: 'image'},
    {src: 'athletic-gaines/mobile/Athletic-Gaines-Web-Images-19A.jpg', type: 'image'},
    {src: 'athletic-gaines/mobile/Athletic-Gaines-Web-Images-19B.jpg', type: 'image'}
]

export  {AthleticGainesData, AthleticGainesDataMob}
import React, {useEffect} from 'react';
import Seo from '../config/seo';
import BaseLayout from '../layouts/BaseLayout';
import Library from '../components/pages/library';

const LibraryPage = () => {
    useEffect(() => {
        document.body.style.height = ``
    }, []);
    return (
        <>
            <Seo
                title="Library"
                description="See our portfolio of work"
            />
            <BaseLayout>
                <Library/>
            </BaseLayout>
        </>
    )
}
export default LibraryPage;

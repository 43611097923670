const BlocksData = [
    {src: 'https://player.vimeo.com/progressive_redirect/playback/791897339/rendition/720p/file.mp4?loc=external&signature=80dfb05fe554d540af03d94c3ebc92c0f06591282951acaa3332084148bb463a', type: 'mp4'},
    {src: 'blocks/blocks-2.jpg', type: 'image'},
    {src: 'blocks/blocks-3.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/791906652/rendition/720p/file.mp4?loc=external&signature=78873e8d395ecc6b4b0966330b4b223468c56f9823239e4bbfd2b34c836987d6', type: 'mp4'},
    {src: 'blocks/blocks-5.jpg', type: 'image'},
    {src: 'https://player.vimeo.com/progressive_redirect/playback/791906722/rendition/720p/file.mp4?loc=external&signature=c5d4fd88b2bde406132286d813b3a4ae3dd8c8d251ad59d86334e1b75f7aec79', type: 'mp4'},
    {src: 'blocks/blocks-7.jpg', type: 'image'},
    {src: 'blocks/blocks-8.jpg', type: 'image'},
    {src: 'blocks/blocks-9.jpg', type: 'image'},
    {src: 'blocks/blocks-10.jpg', type: 'image'},
    {src: 'blocks/blocks-11.jpg', type: 'image'},
    {src: 'blocks/blocks-12.jpg', type: 'image'},
    {src: 'blocks/blocks-13.jpg', type: 'image'},
    {src: 'blocks/blocks-14.jpg', type: 'image'},
    {src: 'blocks/blocks-15.jpg', type: 'image'},
    {src: 'blocks/blocks-16.jpg', type: 'image'},
    {src: 'blocks/blocks-17.jpg', type: 'image'},
    {src: 'blocks/blocks-18.jpg', type: 'image'},
    {src: 'blocks/blocks-20.jpg', type: 'image'},
    {src: 'blocks/blocks-21.jpg', type: 'image'},
    {src: 'blocks/blocks-22.jpg', type: 'image'},
    {src: 'blocks/blocks-23.jpg', type: 'image'},
    {src: 'blocks/blocks-24.jpg', type: 'image'},
    {src: 'blocks/blocks-25.jpg', type: 'image'},
    {src: 'blocks/blocks-26.jpg', type: 'image'},
]

export default BlocksData
const shelfLeft = [
    {
        title: 'Blocks Nutrition',
        id: 1,
        img: 'blocks',
        previewType: 'video',
        preview: 'https://player.vimeo.com/progressive_redirect/playback/791893031/rendition/540p/file.mp4?loc=external&signature=9a2278d61181e8e92420837265be80c9efc9d1b47abaf75401c7f49a3ffdd7d1',
        description: 'Building a healthier world.',
        hash: '#blocks',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'GTM Strategy'
            },
            {
                category: 'Digital Strategy'
            },
            {
                category: 'Cash Flow Strategy'
            },
            {
                category: 'Copywriting'
            },
            {
                category: 'Naming'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Packaging Design'
            },
            {
                category: 'Apparel Design'
            },
            {
                category: 'Print Design'
            },
            {
                category: 'Website Design'
            },
            {
                category: 'Retail Design'
            },
            {
                category: 'Product Design'
            },
            {
                category: 'Merchandise Design'
            },
            {
                category: 'Videography'
            },
            {
                category: 'Photography'
            },
            {
                category: '3D Modeling & Rendering'
            },
            {
                category: 'Animation'
            },
            {
                category: 'Content Creation'
            },
            {
                category: 'Digital Ads'
            },
            {
                category: 'Marketing Collateral'
            },
            {
                category: 'Paid Search'
            },
            {
                category: 'Media Buying'
            },
            {
                category: 'Email Marketing'
            },
            {
                category: 'Website Development'
            },
            {
                category: 'Paid Social Management'
            }
        ]
    },
    {
        title: 'F1 Las Vegas Grand Prix',
        id: 2,
        img: 'f1',
        previewType: 'video',
        preview: 'https://player.vimeo.com/progressive_redirect/playback/792128099/rendition/540p/file.mp4?loc=external&signature=c2ea2388033b31fdb85da88d4716fd18673c3f0643f6edd5c99aff49751f37ab',
        description: 'Bringing the world of F1 to the city of Las Vegas.',
        hash: '#formula1',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'GTM Strategy'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Apparel Design'
            },
            {
                category: 'Print Design'
            },
            {
                category: 'Website Design'
            },
            {
                category: 'Space Design'
            },
            {
                category: 'Product Design'
            },
            {
                category: 'Photography'
            },
            {
                category: '3D Modeling & Rendering'
            },
            {
                category: 'Content Creation'
            },
            {
                category: 'Marketing Collateral'
            }
        ]
    },
    {
        id: 3,
        img: 'b_r',
        title: 'Born & Raised',
        previewType: 'video',
        preview: 'https://player.vimeo.com/progressive_redirect/playback/792130645/rendition/540p/file.mp4?loc=external&signature=386459556df654dfea8580db32d8920d4a82420360545c3f29fc272b6d477817',
        description: 'Bringing a local, family owned farm to your doorstep.',
        hash: '#bornandraised',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'GTM Strategy'
            },
            {
                category: 'Digital Strategy'
            },
            {
                category: 'Copywriting'
            },
            {
                category: 'Naming'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Packaging Design'
            },
            {
                category: 'Merchandise Design'
            },
            {
                category: 'Print Design'
            },
            {
                category: 'Website Design'
            },
            {
                category: 'Retail Design'
            },
            {
                category: 'Videography'
            },
            {
                category: 'Photography'
            },
            {
                category: 'Content Creation'
            },
            {
                category: 'Digital Ads'
            },
            {
                category: 'Marketing Collateral'
            },
            {
                category: 'Paid Search'
            },
            {
                category: 'Website Development'
            }
        ]
    },
    {
        id: 4,
        img: 'oro',
        title: 'ORO Reserve',
        previewType: 'video',
        preview: 'https://player.vimeo.com/progressive_redirect/playback/792134188/rendition/540p/file.mp4?loc=external&signature=40f664cc544d2174a5535e735392600d5c34a8eea84a7201eb17781f88ea3a88',
        description: 'Creating the gold standard in luxury car rentals.',
        hash: '#oro',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'GTM Strategy'
            },
            {
                category: 'Digital Strategy'
            },
            {
                category: 'Copywriting'
            },
            {
                category: 'Naming'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Website Design'
            },
            {
                category: 'Videography'
            },
            {
                category: 'Photography'
            },
            {
                category: 'Animation'
            },
            {
                category: 'Content Creation'
            },
            {
                category: 'Digital Ads'
            },
            {
                category: 'Marketing Collateral'
            },
            {
                category: 'Paid Search'
            },
            {
                category: 'Website Development'
            },
        ]
    },
    {
        id: 5,
        img: 'blrs',
        title: 'BLR$',
        previewType: 'image',
        preview: '/images/library/projects/blr/blrs-6.jpg',
        description: 'Playing with the new form of art.',
        hash: '#blr',
        categories: [
            {
                category: 'Brand Strategy',
            },
            {
                category: 'Naming',
            },
            {
                category: 'Brand Identity',
            },
            {
                category: '3D Modeling & Rendering',
            },
            {
                category: 'Animation',
            }
        ]
    },
    {
        id: 6,
        img: 'kur',
        title: 'Kur Research',
        previewType: 'video',
        preview: 'https://player.vimeo.com/progressive_redirect/playback/792252633/rendition/540p/file.mp4?loc=external&signature=ee23d9f1d01003084e4de4973784b2fca1248eec0f9566418ac962c190a7bad6',
        description: 'Making progress possible with clinical research trials.',
        hash: '#kur',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'Naming'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Print Design'
            },
            {
                category: 'Website Design'
            },
            {
                category: 'Merchandise Design'
            },
            {
                category: 'Animation'
            },
            {
                category: 'Marketing Collateral'
            },
            {
                category: 'Website Development'
            }
        ]
    },
    {
    ///////////////////////
        id: 7,
        img: 'scoop',
        title: 'Local Scoop',
        previewType: 'image',
        preview: '/images/library/projects/scoop/local-scoop-preview.jpg',
        description: 'Transforming package free food & faire in Encinitas, CA.',
        hash: '#local-scoop',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Packaging Design'
            },
            {
                category: 'Print Design'
            },
            {
                category: 'Website Design'
            },
            {
                category: 'Space Design'
            },
            {
                category: 'Retail Design'
            },
            {
                category: 'Photography'
            },
            {
                category: '3D Modeling & Rendering'
            },
            {
                category: 'Website Development'
            }
        ]
    }
]

const shelfRight = [
    {
        id: 8,
        img: 'gaines',
        title: 'Athletic Gaines',
        previewType: 'image',
        preview: '/images/library/projects/athletic-gaines/athletic-gaines-preview.jpg',
        description: 'Branding a sports training icon.',
        hash: '#athletic-gaines',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Apparel Design'
            },
            {
                category: 'Print Design'
            },
            {
                category: 'Space Design'
            },
            {
                category: 'Merchandise Design'
            },
            {
                category: '3D Modeling & Rendering'
            },
            {
                category: 'Marketing Collateral'
            }
        ]
    },
    {
        id: 9,
        img: 'awol',
        title: 'AWOL Sportfishing',
        previewType: 'image',
        preview: '/images/library/projects/awol/awol-preview.jpg',
        description: 'Creating A Way Of Life. Sportfishing in San Diego.',
        hash: '#awol',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'GTM Strategy'
            },
            {
                category: 'Digital Strategy'
            },
            {
                category: 'Copywriting'
            },
            {
                category: 'Naming'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Apparel Design'
            },
            {
                category: 'Website Design'
            },
            {
                category: 'Videography'
            },
            {
                category: 'Photography'
            },
            {
                category: 'Content Creation'
            },
            {
                category: 'Digital Ads'
            },
            {
                category: 'Paid Search'
            },
            {
                category: 'Website Development'
            }
        ]
    },
    {
        id: 10,
        img: 'eckhoff',
        title: 'Ashley Eckhoff',
        previewType: 'video',
        preview: 'https://player.vimeo.com/progressive_redirect/playback/792174357/rendition/540p/file.mp4?loc=external&signature=6ec7ca745b56b65903d96740f35bc1f0e8ab16dfa74587f9fa2776167d996743',
        description: 'Branding an expert of body refinement.',
        hash: '#ashley-eckhoff',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Apparel Design'
            },
            {
                category: 'Print Design'
            },
            {
                category: 'Website Design'
            },
            {
                category: 'Marketing Collateral'
            }
        ]
    },
    {
        id: 11,
        img: 'legoff',
        title: 'LeGoff',
        previewType: 'video',
        preview: 'https://player.vimeo.com/progressive_redirect/playback/792871574/rendition/540p/file.mp4?loc=external&signature=ff7df392ee37f9101a70dda887a9c8704121157a14a37e14682a918bc03b23b1',
        description: 'Refining sophistication in the realm of real estate.',
        hash: '#le-goff',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Apparel Design'
            },
            {
                category: 'Print Design'
            }
        ]
    },
    {
        id: 12,
        img: 'noxx',
        title: 'NOXX',
        previewType: 'image',
        preview: '/images/library/projects/noxx/noxx-preview.jpg',
        description: 'Redefining fishing, 40,000 years later.',
        hash: '#noxx',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'GTM Strategy'
            },
            {
                category: 'Naming'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Apparel Design'
            }
        ]
    },
    {
        id: 13,
        img: 'ashes',
        title: 'Beautiful Ashes',
        previewType: 'image',
        preview: '/images/library/projects/beautiful-ashes/beautiful-ashes-preview.jpg',
        description: 'Standing firm with a road map to freedom.',
        hash: '#beautiful-ashes',
        categories: [
            {
                category: 'Brand Strategy'
            },
            {
                category: 'Digital Strategy'
            },
            {
                category: 'Brand Identity'
            },
            {
                category: 'Print Design'
            },
            {
                category: 'Website Design'
            },
            {
                category: 'Marketing Collateral'
            },
            {
                category: 'Website Development'
            }
        ]
    },
]

const books = [...shelfLeft, ...shelfRight]

const bookTitles = [
    {
        title: 'Blocks',
        id: 1,
        hash: '#blocks',
    },
    {
        title: 'F1 Las Vegas Grand Prix',
        id: 2,
        hash: '#formula1',
    },
    {
        title: 'Born & Raised',
        id: 3,
        hash: '#bornandraised'
    },
    {
        title: 'Oro',
        id: 4,
        hash: '#oro'
    },
    {
        title: 'Blr$',
        id: 5,
        hash: '#blr'
    },
    {
        title: 'Kur',
        id: 6,
        hash: '#kur'
    },
    {
        title: 'Local Scoop',
        id: 7,
        hash: '#local-scoop'
    },
    {
        title: 'Athletic Gaines',
        id: 8,
        hash: '#athletic-gaines'
    },
    {
        title: 'Awol',
        id: 9,
        hash: '#awol'
    },
    {
        title: 'Ashley Eckhoff',
        id: 10,
        hash: '#ashley-eckhoff'
    },
    {
        title: 'Le Goff',
        id: 11,
        hash: '#le-goff'
    },
    {
        title: 'Noxx',
        id: 12,
        hash: '#noxx'
    },
    {
        title: 'Beautiful Ashes',
        id: 13,
        hash: '#beautiful-ashes'
    }
]

export {shelfLeft, shelfRight, books, bookTitles}

import React from 'react'
import SliderWrapper from '../../service/SliderWrapper'

import AwolData from './_data'
import projectGallery from '../../service/ProjectGallery'


const Index = () => {

    return (
        <SliderWrapper>
            {projectGallery(AwolData)}
        </SliderWrapper>
    )
}

export default Index
